var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              staticStyle: { display: "inline-block" },
              attrs: {
                searchList: _vm.$sbglSbsjSearch,
                listQuery: _vm.listQuery,
                dynamic: true,
                noOperation: true,
              },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "app-container flex-item" }, [
        _c("div", { ref: "dragBox", staticClass: "dragBox" }, [
          _c(
            "div",
            { staticClass: "dragBox_left" },
            [
              _c(
                "el-card",
                {
                  staticClass: "body-small",
                  staticStyle: { height: "100%", overflow: "auto" },
                  attrs: { shadow: "never" },
                },
                [
                  _c("basic-tree", {
                    attrs: {
                      isShowCheck: false,
                      receiveTreedata: _vm.orgsTree,
                      incomeTreeData: true,
                      defaultChoose: _vm.defaultChoose,
                    },
                    on: {
                      handleNodeClick: _vm.handleNodeClick,
                      getCheckedKeys: _vm.getCheckedKeys,
                      nodeClickOne: _vm.nodeClickOne,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dragBox_resize", attrs: { title: "收缩侧边栏" } },
            [_vm._v(" ⋮ ")]
          ),
          _c("div", { staticClass: "dragBox_mid" }, [
            _c(
              "div",
              {
                staticClass: "bg-white",
                staticStyle: { height: "100%" },
                attrs: { id: "table_box_height" },
              },
              [
                _c("div", { staticClass: "table_top" }, [
                  _c(
                    "div",
                    { staticClass: "table_top_left" },
                    [
                      _c(
                        "el-card",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { span: 3 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "20px" } },
                                    [
                                      _vm.listQuery.deviceStatisticType == 1
                                        ? _c("p", [_vm._v("全部类型")])
                                        : _vm._e(),
                                      _vm.listQuery.deviceStatisticType == 2
                                        ? _c("p", [_vm._v("闸门类")])
                                        : _vm._e(),
                                      _vm.listQuery.deviceStatisticType == 3
                                        ? _c("p", [_vm._v("流量计类")])
                                        : _vm._e(),
                                      _vm.listQuery.deviceStatisticType == 4
                                        ? _c("p", [_vm._v("水位计类")])
                                        : _vm._e(),
                                      _c("p", [_vm._v("设备数据统计分析")]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { span: 3 },
                                },
                                [
                                  _vm.listQuery.deviceStatisticType == 1
                                    ? _c("el-image", {
                                        staticStyle: { width: "160px" },
                                        attrs: {
                                          src: require("@/assets/device_images/5.jpg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.listQuery.deviceStatisticType == 2
                                    ? _c("el-image", {
                                        staticStyle: { width: "160px" },
                                        attrs: {
                                          src: require("@/assets/device_images/5.jpg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.listQuery.deviceStatisticType == 3
                                    ? _c("el-image", {
                                        staticStyle: { width: "160px" },
                                        attrs: {
                                          src: require("@/assets/device_images/11.jpg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.listQuery.deviceStatisticType == 4
                                    ? _c("el-image", {
                                        staticStyle: { width: "160px" },
                                        attrs: {
                                          src: require("@/assets/device_images/10.jpg"),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c("el-progress", {
                                    attrs: {
                                      type: "dashboard",
                                      percentage: _vm.equipmentList.totalNum,
                                      color: "#2fc25b",
                                      format: _vm.totalNumFormat,
                                      "text-color": "#e6a23c",
                                      "stroke-width": 16,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c("el-progress", {
                                    staticStyle: { margin: "0 20px" },
                                    attrs: {
                                      type: "dashboard",
                                      percentage: _vm.equipmentList.onlineNum,
                                      color: "#FC8452",
                                      format: _vm.onlineNumFormat,
                                      "text-color": "#f56c6c",
                                      "stroke-width": 16,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c("el-progress", {
                                    attrs: {
                                      type: "dashboard",
                                      percentage: _vm.equipmentList.onlineRate,
                                      color: "#1890FF",
                                      format: _vm.onlineRateFormat,
                                      "text-color": "#5cb87a",
                                      "stroke-width": 16,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "table_top_right" }, [
                    _vm.isList
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/img/listicon.png"),
                            alt: "",
                          },
                          on: { click: _vm.changeList },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/img/charticon.png"),
                            alt: "",
                          },
                          on: { click: _vm.changeList },
                        }),
                  ]),
                  _c("div", { staticClass: "clear" }),
                ]),
                _vm.isList
                  ? _c("basic-table", {
                      attrs: {
                        tableHeight: _vm.tableHeight,
                        otherColumn: _vm.otherColumn,
                        tableHeaderList: _vm.firstHeaderList,
                        tableData: _vm.tableData,
                        listQuery: _vm.listQuery,
                        tableTotal: _vm.tableTotal,
                        hasSelection: true,
                        isCbxx: true,
                      },
                      on: {
                        pagination: _vm.handleCurrentChange,
                        tableRowClick: _vm.tableRowClick,
                        handleSelectionChange: _vm.handleSelectionChange,
                        tableColumnClick: _vm.tableColumnClick,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "imgSrc",
                            fn: function (row) {
                              return [
                                row.rowData.installedImage
                                  ? _c("el-image", {
                                      ref: "myImg",
                                      staticStyle: {
                                        width: "24px",
                                        height: "24px",
                                        cursor: "pointer",
                                      },
                                      attrs: {
                                        src: require("@/assets/img/thumbnail.png"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.viewImg(
                                            $event,
                                            row.rowData
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2689917530
                      ),
                    })
                  : [
                      _c(
                        "div",
                        {
                          staticClass: "chartList",
                          style: { height: _vm.tableHeight + "px" },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { gutter: 12 },
                            },
                            _vm._l(_vm.tableData, function (j, jdx) {
                              return _c(
                                "el-col",
                                { key: jdx, attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-card",
                                    {
                                      staticClass: "chartList_item",
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.tableColumnClick(j)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "chartList_left_img" },
                                        [
                                          j.installedImage
                                            ? _c("div", [
                                                _c("img", {
                                                  staticStyle: {
                                                    widows: "100%",
                                                    height: "200px",
                                                  },
                                                  attrs: {
                                                    src:
                                                      _vm.installedImageFile +
                                                      j.installedImage,
                                                    alt: "",
                                                  },
                                                }),
                                              ])
                                            : _c("div"),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "chartList_right_detils",
                                        },
                                        [
                                          _c("p", { staticClass: "detils_p" }, [
                                            _vm._v(
                                              "设备编号：" + _vm._s(j.deviceNo)
                                            ),
                                          ]),
                                          _c("p", { staticClass: "detils_p" }, [
                                            _vm._v(
                                              "设备类型：" +
                                                _vm._s(j.deviceTypeName)
                                            ),
                                          ]),
                                          _c("p", { staticClass: "detils_p" }, [
                                            _vm._v(
                                              "安装地点：" +
                                                _vm._s(j.installedLocation)
                                            ),
                                          ]),
                                          _c("p", { staticClass: "detils_p" }, [
                                            _vm._v(
                                              "版本号：" + _vm._s(j.versionNo)
                                            ),
                                          ]),
                                          _c("p", { staticClass: "detils_p" }, [
                                            _vm._v(
                                              "更新时间：" +
                                                _vm._s(j.lastReadTime)
                                            ),
                                          ]),
                                          _c("p", { staticClass: "detils_p" }, [
                                            _vm._v(
                                              "在线状态：" +
                                                _vm._s(
                                                  j.status == "1"
                                                    ? "在线"
                                                    : "在装"
                                                )
                                            ),
                                          ]),
                                          _c(
                                            "el-descriptions",
                                            {
                                              staticClass: "left_descriptions",
                                              attrs: { column: 2 },
                                            },
                                            [
                                              j.deviceTypeId == "HS_CKYTHZM"
                                                ? [
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "流量计水位",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              j.waterLevel_LLJ
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "流量计水深",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(j.waterDepth)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "水位计水位",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              j.waterLevel_SWJ
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "累计流量",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(j.totalFlow)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "流速",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              j.flowVelocity
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "闸位",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              j.gatePosition
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "水温",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              j.waterTemperature
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : j.deviceTypeId == "HS_SWJ"
                                                ? [
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "水位",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              j.waterLevel_SWJ
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : [
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "水位",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              j.waterLevel_LLJ
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "水深",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(j.waterDepth)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "累计流量",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(j.totalFlow)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "流速",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              j.flowVelocity
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-descriptions-item",
                                                      {
                                                        attrs: {
                                                          label: "水温",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              j.waterTemperature
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("pagination", {
                        attrs: {
                          total: _vm.tableTotal,
                          page: _vm.listQuery.page,
                          limit: _vm.listQuery.limit,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.listQuery, "page", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.listQuery, "limit", $event)
                          },
                          pagination: _vm.handleCurrentChange,
                        },
                      }),
                    ],
              ],
              2
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看图片",
            visible: _vm.imgDialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imgDialogVisible = $event
            },
          },
        },
        [
          _c("el-image", { attrs: { src: _vm.previewImgSrc } }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.imgDialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.FormDialog,
          FormList: _vm.FormList,
          title: _vm.addTitle,
          FormData: _vm.addform,
          isAreaTree: true,
        },
        on: {
          cancelDialog: _vm.cancelDialog,
          confirmDialog: _vm.confirmDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }