<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$sbglSbsjSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :dynamic='true' :noOperation="true"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="false" :receiveTreedata="orgsTree" :incomeTreeData="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne" :defaultChoose="defaultChoose"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <div class="table_top">
              <div class="table_top_left">
                <el-card  >
                  <el-row>
                    <el-col :span="3" style="text-align: center;">
                      <div style="margin-top: 20px;">
                        <p v-if="listQuery.deviceStatisticType == 1">全部类型</p>
                        <p v-if="listQuery.deviceStatisticType == 2">闸门类</p>
                        <p v-if="listQuery.deviceStatisticType == 3">流量计类</p>
                        <p v-if="listQuery.deviceStatisticType == 4">水位计类</p>
                        <p>设备数据统计分析</p>
                      </div>
                    </el-col>
                    <el-col :span="3" style="text-align: center;">
                      <el-image style="width: 160px;" :src="require('@/assets/device_images/5.jpg')"  v-if="listQuery.deviceStatisticType == 1"></el-image>
                      <el-image style="width: 160px;" :src="require('@/assets/device_images/5.jpg')"  v-if="listQuery.deviceStatisticType == 2"></el-image>
                      <el-image style="width: 160px;" :src="require('@/assets/device_images/11.jpg')"  v-if="listQuery.deviceStatisticType == 3"></el-image>
                      <el-image style="width: 160px;" :src="require('@/assets/device_images/10.jpg')"  v-if="listQuery.deviceStatisticType == 4"></el-image>
                    </el-col>
                    <el-col :span="6" style="text-align: center;">
                      <el-progress type="dashboard" :percentage="equipmentList.totalNum" :color="'#2fc25b'" :format="totalNumFormat" :text-color="'#e6a23c'" :stroke-width="16"></el-progress>
                    </el-col>
                    <el-col :span="6" style="text-align: center;">
                      <el-progress style="margin: 0 20px;" type="dashboard" :percentage="equipmentList.onlineNum" :color="'#FC8452'" :format="onlineNumFormat" :text-color="'#f56c6c'" :stroke-width="16"></el-progress>
                    </el-col>
                    <el-col :span="6" style="text-align: center;">
                      <el-progress type="dashboard" :percentage="equipmentList.onlineRate" :color="'#1890FF'" :format="onlineRateFormat" :text-color="'#5cb87a'" :stroke-width="16"></el-progress>
                    </el-col>
                  </el-row>
                </el-card>

                <!-- <el-card v-for="(i,index) in equipmentList" :key="index" class="card_item">
                  <span>
                    {{ i.deviceName }}
                    <el-tooltip class="item" effect="dark" content="总数量/在线数量/在线率" placement="bottom-start">
                      <i class="el-icon-question" style="color:#409eff"></i>
                    </el-tooltip>
                     ：
                  </span>
                  <span>{{ i.totalNum }}/{{ i.onlineNum }}/{{ i.onlineRate }}</span>
                </el-card> -->
              </div>
              <div class="table_top_right">
                <img src="@/assets/img/listicon.png" alt="" v-if="isList" @click="changeList">
                <img src="@/assets/img/charticon.png" alt="" v-else @click="changeList">
              </div>
              <div class="clear"></div>
            </div>
            <!-- 列表页 -->
            <basic-table v-if="isList" :tableHeight="tableHeight" :otherColumn="otherColumn" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :hasSelection="true" @handleSelectionChange="handleSelectionChange" :isCbxx="true"  @tableColumnClick="tableColumnClick">
              <template v-slot:imgSrc="row">
                <el-image ref="myImg"  v-if="row.rowData.installedImage" style="width: 24px; height: 24px;cursor: pointer;" :src="require('@/assets/img/thumbnail.png')" @click.stop="viewImg($event,row.rowData)"> </el-image> 
              </template></basic-table >
            <!-- 图表页 -->
            <template v-else >
              <div class="chartList" :style="{height: tableHeight  + 'px',}">
                <el-row :gutter="12" style="width: 100%;">
                  <el-col :span="6" v-for="(j,jdx) in tableData" :key="jdx">
                    <el-card class="chartList_item" @click.native="tableColumnClick(j)">
                      <!-- <p class="title"> {{ j.updateTime }}</p>  -->
                      <!-- {{ j.userName }} —— -->
                      <div class="chartList_left_img">
                        <div v-if="j.installedImage">
                          <img :src="installedImageFile+j.installedImage" style="widows: 100%;height: 200px;" alt="">
                        </div>
                        <div v-else>
                        </div>
                      </div>
                      <div class="chartList_right_detils">
                        <p class="detils_p">设备编号：{{ j.deviceNo }}</p>
                        <p class="detils_p">设备类型：{{ j.deviceTypeName }}</p>
                        <p class="detils_p">安装地点：{{ j.installedLocation }}</p>
                        <p class="detils_p">版本号：{{ j.versionNo }}</p>
                        <p class="detils_p">更新时间：{{ j.lastReadTime }}</p>
                        <p class="detils_p">在线状态：{{ j.status == '1'?'在线':'在装' }}</p>
                        <el-descriptions :column="2" class="left_descriptions">
                          <template v-if="j.deviceTypeId=='HS_CKYTHZM'">
                            <el-descriptions-item label="流量计水位"> {{ j.waterLevel_LLJ }}</el-descriptions-item>
                            <el-descriptions-item label="流量计水深"> {{ j.waterDepth }}</el-descriptions-item>
                            <el-descriptions-item label="水位计水位"> {{ j.waterLevel_SWJ }}</el-descriptions-item>
                            <el-descriptions-item label="累计流量"> {{ j.totalFlow }}</el-descriptions-item>
                            <el-descriptions-item label="流速"> {{ j.flowVelocity }}</el-descriptions-item>
                            <el-descriptions-item label="闸位"> {{ j.gatePosition }}</el-descriptions-item>
                            <el-descriptions-item label="水温"> {{ j.waterTemperature }}</el-descriptions-item>
                          </template>
                          <template v-else-if="j.deviceTypeId=='HS_SWJ'">
                            <el-descriptions-item label="水位"> {{ j.waterLevel_SWJ }}</el-descriptions-item>
                          </template>
                          <template v-else>
                            <el-descriptions-item label="水位"> {{ j.waterLevel_LLJ }}</el-descriptions-item>
                            <el-descriptions-item label="水深"> {{ j.waterDepth }}</el-descriptions-item>
                            <el-descriptions-item label="累计流量"> {{ j.totalFlow }}</el-descriptions-item>
                            <el-descriptions-item label="流速"> {{ j.flowVelocity }}</el-descriptions-item>
                            <el-descriptions-item label="水温"> {{ j.waterTemperature }}</el-descriptions-item>
                          </template>
                          <!-- <el-descriptions-item label="报警状态"> {{ j.alarmStatus }}</el-descriptions-item>
                          <el-descriptions-item label="报警内容"> {{ j.alarmDeviceStatusDesc }}</el-descriptions-item> -->
                        </el-descriptions>
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
              </div>
              <pagination :total="tableTotal" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="handleCurrentChange" />
            </template>
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>
    <el-dialog title="查看图片" :visible.sync="imgDialogVisible" width="30%" >
      <el-image :src="previewImgSrc" > </el-image>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform" :isAreaTree="true"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>
    
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hsycjkequipmentmanagement',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        installedImageFile:process.env.VUE_APP_BASE_IMG_URL +'/File/',
        previewImgSrc:"",//预览图片列表
        imgDialogVisible:false,
        otherColumn:[//
          {
            label:'设备安装图片',
            slotName:'imgSrc',
          }
        ],
        equipmentList:{
          totalNum: 0,
          onlineNum:0,
          onlineRate:0,
          totalNumFormat:0,
          onlineNumFormat:0
        },
        isList:true,//是否是列表
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          deviceStatisticType:'1',
          key:"",
          TreeIds:[],//选中集合
          queryType: '0',
          queryKey:'UserNumber',
          queryValue:'',
          OtherKey:'',//查询条件
          OtherValue:'',//查询内容
          issueType:'0',//操作范围
          ids:[],//操作范围传值
          beginTime:'',
          endTime:'',
          repeat:'',
          timer:[],
          HS_Area:""
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{
          UserNumber:'',//用户编号
          SurfaceNum:'',//表编号
          SurfaceAddress:'',//表地址
          CurrentReading:'',//当前读数
        },
        FormList:{//表格列
          column:[
            {columnKey:'DeviceNo',columnDescription:'设备编号',columnType:'text',prop:'DeviceNo',width:'120',placeholder:"设备编号",step:0.0001,},
            {columnKey:'VersionNo',columnDescription:'软件版本号',columnType:'text',prop:'VersionNo',width:'120',placeholder:"软件版本号",},
            {columnKey:'InstalledLocation',columnDescription:'安装地点',columnType:'text',prop:'InstalledLocation',width:'120',placeholder:"安装地点",},
            {columnKey:'Longitude',columnDescription:'经度坐标',columnType:'text',prop:'Longitude',width:'120',placeholder:"经度坐标",},
            {columnKey:'Latitude',columnDescription:'纬度坐标',columnType:'text',prop:'Latitude',width:'120',placeholder:"纬度坐标",},
            {columnKey:'WaterConsumptionNorm',columnDescription:'用水定额(m³)',columnType:'text',prop:'WaterConsumptionNorm',width:'120',placeholder:"用水定额",},
            {columnKey:'IrrigatedAcreage',columnDescription:'灌溉亩数(亩)',columnType:'text',prop:'IrrigatedAcreage',width:'120',placeholder:"灌溉亩数",},
            {columnKey:'CropTypeId',columnDescription:'作物类型',columnType:'text',prop:'CropTypeId',width:'120',placeholder:"作物类型",},
            {
              columnKey:'fatherAreaId',
              columnDescription:'所属机构',
              columnType:'selectAreaTree',
              prop:'fatherAreaId',
              width:'120',
              placeholder:"所属机构",
            },
            {columnKey:'DeviceRemark',columnDescription:'设备描述',columnType:'textarea',prop:'DeviceRemark',width:'120',placeholder:"设备描述",},
            {columnKey:'InstalledImage',columnDescription:'设备安装图片',columnType:'upload',prop:'InstalledImage',width:'120',placeholder:"请选择设备安装图片",},
          ],
          //校验
          rule:{
            CurrentReading: [{ required: true, message: "当前读数不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'设备维护',//添加弹窗标题
        instructionTitle:'',//指令弹窗标题
        instructionDialog:false,//指令弹窗控制
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        chooseList:[],//多选数据
        defaultChoose:[],//通过跳转过来的页面默认选中树
      }
    },
    watch: {
      'listQuery.deviceStatisticType': {
          handler() {
            this.getList()
          },
        }
    },
    computed: {
      orgsTree() {
        return this.$store.state.allInstitution;
      },
    },
    filters: {
      
    },
    created() {
      if(this.$route.query.id){//如果是页面跳转
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(this.$route.query.id)
        this.defaultChoose = []
        this.defaultChoose.push(this.$route.query.id)
      }
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-250
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-250
          this.$forceUpdate()
        });
        // 计算table高度
      })
      // this.dragControllerDiv();
      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
     //点击图片
     viewImg(e,row){
        this.previewImgSrc =  process.env.VUE_APP_BASE_IMG_URL +"/File/"+row.installedImage
        this.imgDialogVisible = true
      },
      totalNumFormat(){
        return  this.equipmentList.totalNumFormat+'\n\n在线'
      },
      onlineNumFormat(){
        return  this.equipmentList.onlineNumFormat+'\n\n在装'
      },
      onlineRateFormat(){
        return  this.equipmentList.onlineRate+'%\n\n在线率'
      },
      //切换列表还是图标
      changeList(){
        this.isList = !this.isList
      },
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        // console.log(data)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.HS_Area=data.id
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        if(domId == 'btnAdd'){//新增
          this.FormDialog = true
          return
        }
        if(domId == 'btnEdit'){//编辑
          if(this.chooseList.length != 0){
            this.$message.error('请选择一个设备进行操作！');
            return;
          }
          this.FormDialog = true
          return
        }
        if(domId == 'btnDel'){//删除
          if(this.chooseList.length <= 0){
            this.$message.error('请选择需要删除的设备！');
            return;
          }
          this.$message.success('删除成功');
          return
        }
        
        if(domId == 'btnExport'){//导出
          this.handleDownExcel()
          return
        }
      },
      // 获取数据
      getList() {
        this.loading = true
        var searchQuery = Object.assign({},this.listQuery)
        if(searchQuery.timer&&searchQuery.timer.length == 2){
          searchQuery.StartTime = this.$basicFun.dataFormat(searchQuery.timer[0],'yyyy-MM-dd hh:mm');
          searchQuery.EndTime = this.$basicFun.dataFormat(searchQuery.timer[1],'yyyy-MM-dd hh:mm');
        }
        this.loading = true
        basicDataApi.sbglSbsjSearch(searchQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })

        basicDataApi.sbsjsjtj(searchQuery).then(response => {
          if(response.code == 200){
             this.equipmentList.totalNum= parseFloat(((response.result.onLineCount/response.result.totalCount_ALL)*100).toFixed(2))
             this.equipmentList.totalNumFormat = response.result.onLineCount
            this.equipmentList.onlineNum= parseFloat( ((response.result.totalCount/response.result.totalCount_ALL)*100).toFixed(2))
            this.equipmentList.onlineNumFormat = response.result.totalCount
            this.equipmentList.onlineRate= response.result.onLineRate


          }else{
          }
        }).catch(()=>{
        })

      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.listQuery.HS_Area =''
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      //多选
      handleSelectionChange(val){
        this.chooseList = val
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        console.log(this.addform)
        if(!this.addform.UserNumber && !this.addform.SurfaceNum && !this.addform.SurfaceAddress){
          this.$notify({
            message: '至少输入用户编号、表编号、表地址中的一个',
            type: 'warning',
            duration: 2000
          });
          return
        }
        basicDataApi.cbxxRgcb(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.addform={
              UserNumber:'',//用户编号
              SurfaceNum:'',//表编号
              SurfaceAddress:'',//表地址
              CurrentReading:'',//当前读数
            }
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.FormDialog = false
        })
      },
      // 导出
      handleDownExcel() {
        this.loading = true
        if(this.listQuery.dateScope){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.dateScope[0],'yyyy-MM-dd');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.dateScope[1],'yyyy-MM-dd');
        }
        basicDataApi.cbxxExport(this.listQuery).then(response => {
          if(response.code == 200){
            var url = process.env.VUE_APP_BASE_IMG_URL + '/'+ response.result
            window.location.href = url
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        })
      },
      //人工抄表
      artificialMemter(){
        this.FormDialog = true
      },
      //点击跳转列
      tableColumnClick(row){
        var allUrl = window.location.href
        const index = allUrl.lastIndexOf("/#/")
        const url = allUrl.substring(0, index+3) + 'hsycjkequipmentmanagement/details'
        // href="${url}?row=${encodeURIComponent(JSON.stringify(evt.cluster.geometries[0].data))}
          // 创建 <a> 元素
        const link = document.createElement('a');
        // 设置 href 属性
        link.href = url+"?row="+encodeURIComponent(JSON.stringify(row))
        // 将 <a> 元素添加到文档中
        document.body.appendChild(link);
        // 模拟点击事件进行页面跳转
        link.click();
        // 在合适的时机，将 <a> 元素从文档中移除
        setTimeout(() => {
          document.body.removeChild(link);
        }, 500); // 假设
        // this.$router.push({path: '/hsycjkequipmentmanagement/details',query:{row}});
      },
    },
  }

</script>

<style lang="scss" scoped>
  .table_top{
    .table_top_left{
      // width: 95%;
      // float: left;
      .card_item{
        float: left;
        width: 25%;
      }
      ::v-deep .el-card__body{
        padding: 5px 20px;
      }
    }
    .table_top_right{
      // width: 5%;
      float: right;
      margin: 5px 1% 0 0;
      img{
        width: 18px;
        cursor: pointer;
      }
    }
  }
  .chartList{
    overflow-y: scroll;
    .chartList_item{
      min-height: 455px;
      ::v-deep .el-card__body{
        padding: 0;
      }
      padding: 5px;
      cursor: pointer;
      .title{
        font-weight: bold;
        margin: 0;
        padding: 5px 0;
        text-align: center;
        display: -webkit-box;  
        -webkit-line-clamp: 1;  
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
      .chartList_left_img{
        height: 200px;
        img{
          width: 100%;
         
        }
      }
      .chartList_right_detils{

        .detils_p{
          // width: 50%;
          // float: left;
          margin: 2px 0 ;
          font-size: 14px;
        }
      }
    }
  }
  ::v-deep .el-progress__text {
    white-space: pre;
  }
::v-deep .el-progress__text{
  margin-left: 0;
}
.left_descriptions ::v-deep .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell{padding: 2px 0;color: #303133;}
.left_descriptions ::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell{padding: 2px 0;color: #303133;}
.left_descriptions ::v-deep .el-descriptions-item__container .el-descriptions-item__label{margin-right: 0;}
</style>
